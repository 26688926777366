import State from '@/interfaces/State';
import axiosApiConfig from '@/axiosApiConfig';
import Rack, { RackType } from '@/interfaces/Rack';
import Warehouse from '@/interfaces/Warehouse';
import { PaginationResponse } from '@/hooks/usePaginationService';
import IRackInventory from '@/interfaces/IRackInventory';
import Staff from '@/interfaces/Staff';

const getRacks = async (params?: any): Promise<Rack[]> => {
  const result : any = await axiosApiConfig.get('/racks', { 
    params: { 
      ...params,
      // scope: RackType.Regular,
    },
  });
  return result.data.data;
};

export const getRackById = async (id: Rack['id']): Promise<Rack> => {
  const result : any = await axiosApiConfig.get(`/racks/${id}`);

  // const matchingIdRack: Rack = result.data.data.find((rack:Rack) => rack.id === id);
  console.log('result', result);
  return result.data;
};

export const getPersonalRackByUserId = async (userId: Staff['id']): Promise<Rack> => {
  const result : any = await axiosApiConfig.get('/racks', { 
    params: { 
      userId,
      type: 'perso',
    },
  });

  // const matchingIdRack: Rack = result.data.data.find((rack:Rack) => rack.id === id);
  // console.log('result', result.data.data, result.data.data[0], matchingIdRack);
  return result.data.data[0];
};

export const getPersonalRackByUser = async (
  userId: Staff['id'],
): Promise<Rack> => {
  const result : any = await axiosApiConfig.get('/racks', { 
    params: { 
      userId,
      type: 'perso',
    },
  });

  // const matchingIdRack: Rack = result.data.data.find((rack:Rack) => rack.id === id);
  // console.log('result', result.data.data, result.data.data[0], matchingIdRack);
  return result.data.data[0];
};

export const getRacksByWarehouse = async (warehouseId: Warehouse['id']): Promise<Rack[]> => {
  const result : any = await axiosApiConfig.get('/racks', { 
    params: { 
      warehouseId,
    }, 
  });
  return result.data.data;
};

// eslint-disable-next-line no-undef
export const createRack = async (data: any)
: Promise<Rack> => {
  const result = await axiosApiConfig.post<any>('/racks', {
    type: RackType.Custom,
    ...data,
  });
  return result.data;
};

// eslint-disable-next-line no-undef
export const editRack = async (data: Partial<Rack>): Promise<any> => {
    const result = await axiosApiConfig.patch<any>(`/racks/${data.id}`, data);
    return result.data;
};

// eslint-disable-next-line no-undef
export const deleteRack = async (data: Pick<Rack, 'id'>): Promise<any> => {
  const result = await axiosApiConfig.delete<any>(`/racks/${data.id}`);
  return result.data;
};

export async function getRackInventory(skip: number, limit: number, params?: any)
: Promise<PaginationResponse<IRackInventory>> {
  const result: any = await axiosApiConfig.get('/rack_inventory', {
      params: {
          ...params,
          skip,
          limit,
      },
  });

  return result.data;
}

export async function getAllRackInventory(params?: any)
: Promise<IRackInventory[]> {
  const result: any = await axiosApiConfig.get('/rack_inventory', {
      params: {
          ...params,
      },
  });
  
  if (result.status !== 200) {
    console.error(result);
    return [];
  }

  return result.data;
}

export const getRacksByInId = async (queryParams: string) => {
  const result = await axiosApiConfig.get(`/racks?${queryParams}`);

  return result.data.data;
};

export default getRacks;
